@import 'styles/mixins.scss';

//
// Page : Agency
// slug : /notre-agence
//
.agency {

    picture {
        display: inline-block;
        font-size: 0;
        max-width: 100%;
        overflow: hidden;
        width: rem(616px);
        height: rem(633px);

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        @media only screen and (max-width: rem(768px)) {
            height: auto;
        }
    }

    .agency-presentation {
        margin-bottom: rem(48px);
        
        .column-half:first-child {
            display: flex;
            flex-direction: column;
            padding: rem(10px) 0;
            
            .picto-container {
                align-items: center;
                display: flex;
                flex: 1;
                justify-content: center;
                width: 100%;
                padding: rem(15px) 0;
            }
            
            .button {
                align-self: center;
            }
        }
    }
    
  
    
    .agency-mdournel, .agency-fbeaumont {
        margin-bottom: rem(48px);
        align-items: center;

        .column-half {
            
            h2 {
                margin-bottom: rem(16px);
            }
        }
    }

    $percent: rem(50px);
    .agency-presentation picture,
    .agency-fbeaumont picture {
        border-radius: 0 $percent $percent $percent;
    }

    .agency-mdournel picture {
        border-radius: $percent 0 $percent $percent;
    }
}
