@import 'styles/mixins.scss';

.scrollTo {
    align-items: center;
    background: transparent;
    border: none;
    bottom: 0;
	color: color(white);
    cursor: pointer;
    display: flex;
    flex-direction: column;
	text-align: center;
	font-size: rem(16px);
	font-family: 'Poppins';
	font-style: normal;
    font-weight: 300;
    justify-content: center;
    left: 50%;
	line-height: normal;
    padding: rem(20px) rem(40px);
    position: fixed;
    text-decoration: underline;
    text-underline-offset: rem(5px);
    transform: translateX(-50%);
    transition: opacity .1s linear;
    white-space: nowrap;
    z-index: 2;

    svg {
        fill: color(white);
        height: rem(36px);
        margin-top: rem(18px);
        width: rem(59px);
    }

    &:hover,
    &:focus-visible {
        outline: none;

        svg {
            animation-name: scrollToFocus;
            animation-duration: 1.5s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
        }
    }
}

@keyframes scrollToFocus {
    0% { transform: translateY(0%); opacity: 1; }
    75%, 100% { transform: translateY(75%); opacity: 0; }
}