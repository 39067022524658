@import 'styles/mixins.scss';

.header {
    align-items: center;
    background: color(white);
    border-radius: rem(10px);
    box-shadow: 0 0 rem(15px) 0 rgba(0, 0, 0, .5);
    display: flex;
    height: rem(100px);
    justify-content: space-between;
    margin: 0 auto;
    max-width: rem(1260px);
    padding: 0 rem(10px);
    position: sticky;
    top: rem(20px);
    transition: opacity .1s linear;
    width: calc(100vw - #{rem(30px)});
    z-index: 4;

    &:has(:focus-visible) {
        opacity: 1 !important;
    }

    &, * {
        will-change: opacity, x, y;
    }

    &:hover {
        opacity: 1 !important;
    }

    .header__title {
        align-items: center;
        color: color(black);
        display: flex;
        height: 100%;
        font-size: rem(36px);
        font-weight: 700;
        padding: 0 rem(25px);
        text-decoration: none;

        &:focus-visible {
            outline: rem(1px) solid color(blue);
            outline-offset: rem(-7px);
        }
    }

    .header__nav {
        height: 100%;
        
        .header__list {
            display: flex;
            height: 100%;
            list-style-type: none;
            margin: 0;
            padding: 0;

            .header__item {
    
                .header__link {
                    align-items: center;
                    color: color(black);
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    padding: 0 rem(16px);
                    padding-top: rem(13px);
                    text-decoration: none;
                    transition: color .3s ease;
    
                    &.header__link-current {
                        color: color(orange);
                        font-weight: bold;
                    }

                    &:focus,
                    &:hover {
                        color: color(orange);
                    }

                    &:focus-visible {
                        outline: none;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .header__mobile_button {
        appearance: none;
        background: none;
        border: none;
        cursor: pointer;
        color: color(blue);
        height: rem(100px);
        transition: color .1s linear;
        width: rem(100px);
    }

    & + .header__overlay {
        background: rgba(color(blue), .80);
        display: none;
        inset: 0;
        opacity: 0;
        position: fixed;
        pointer-events: none;
        transition: opacity .1s linear;
        z-index: 3;
    }

    @media only screen and (max-width: rem(869px)) {

        .header__nav {

            .header__list {

                .header__item {
        
                    .header__link {
                        padding-top: 0;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: rem(640px)) {
        opacity: 1 !important;

        .header__nav {
            background: color(white);
            border-radius: rem(10px);
            box-shadow: 0 0 rem(15px) 0 rgba(0, 0, 0, .5);
            left: 0;
            position: absolute;
            right: 0;
            top: rem(110px);
            height: auto;
            z-index: 100;
            opacity: 0;
            pointer-events: none;
            transition: opacity .1s linear;

            .header__list {
                flex-direction: column;

                .header__item {
        
                    .header__link {
                        color: color(black);
                        font-size: rem(24px);
                        height: rem(75px);
                        width: 100%;
                    }
                }
            }
        }

        & + .header__overlay {
            display: block;
        }

        &.mobile-open {

            .header__nav {
                opacity: 1;
                pointer-events: auto;
            }

            & + .header__overlay {
                opacity: 1;
                pointer-events: auto;
            }
        }
    }
}

.is-orange .header .header__mobile_button {
    color: color(orange);
}

// Hidden
.header.hidden {
    opacity: 0;
}

// Always visible
.header-always-visible {
    .header {
        opacity: 1 !important;
    }
}
