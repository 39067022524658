@import 'styles/mixins.scss';

$size: rem(60px);

.loader {
	height: rem(50px);
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	width: rem(85px);
	z-index: 2;
}

.loader-animation {
	display: inline-block;
    position: relative;
    width: rem(85px);
    height: rem(50px);
    background-image: linear-gradient(#{color(orange)} rem(50px), transparent 0),
                      linear-gradient(#{color(orange)} rem(50px), transparent 0),
                      linear-gradient(#{color(orange)} rem(50px), transparent 0),
                      linear-gradient(#{color(orange)} rem(50px), transparent 0),
                      linear-gradient(#{color(orange)} rem(50px), transparent 0),
                      linear-gradient(#{color(orange)} rem(50px), transparent 0);
    background-repeat: no-repeat;
    background-position: 0 center, rem(15px) center, rem(30px) center, rem(45px) center, rem(60px) center, rem(75px) center, rem(90px) center;
    animation: loading 0.65s linear infinite alternate;
}

@keyframes loading {
	0% { background-size: rem(10px) rem(3px); }
	16% { background-size: rem(10px) rem(50px), rem(10px) rem(3px), rem(10px) rem(3px), rem(10px) rem(3px), rem(10px) rem(3px), rem(10px) rem(3px); }
	33% { background-size: rem(10px) rem(30px), rem(10px) rem(50px), rem(10px) rem(3px), rem(10px) rem(3px), rem(10px) rem(3px), rem(10px) rem(3px); }
	50% { background-size: rem(10px) rem(10px), rem(10px) rem(30px), rem(10px) rem(50px), rem(10px) rem(3px), rem(10px) rem(3px), rem(10px) rem(3px); }
	66% { background-size: rem(10px) rem(3px), rem(10px) rem(10px), rem(10px) rem(30px), rem(10px) rem(50px), rem(10px) rem(3px), rem(10px) rem(3px); }
	83% { background-size: rem(10px) rem(3px), rem(10px) rem(3px),  rem(10px) rem(10px), rem(10px) rem(30px), rem(10px) rem(50px), rem(10px) rem(3px); }
	100% { background-size: rem(10px) rem(3px), rem(10px) rem(3px), rem(10px) rem(3px),  rem(10px) rem(10px), rem(10px) rem(30px), rem(10px) rem(50px); }
}

// Color
.loader-animation {


	.is-orange & {
		background-image: linear-gradient(#{color(blue)} rem(50px), transparent 0),
						  linear-gradient(#{color(blue)} rem(50px), transparent 0),
						  linear-gradient(#{color(blue)} rem(50px), transparent 0),
						  linear-gradient(#{color(blue)} rem(50px), transparent 0),
						  linear-gradient(#{color(blue)} rem(50px), transparent 0),
						  linear-gradient(#{color(blue)} rem(50px), transparent 0);
	}
}