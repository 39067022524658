@import 'styles/mixins.scss';

//
// Page : Contact
// slug : /nous-contacter
//
.contact {

    .contact-listing {
        border-radius: rem(15px);
        background: color(white);
        color: color(grey-dark);
        margin-bottom: rem(128px);
        margin-top: rem(170px);
        padding: rem(64px) rem(64px);
        position: relative;
        z-index: 1;
        
        h1 {
            color: color(blue);
            margin-bottom: rem(64px);
        }

        ul {

            li {
                align-items: flex-start;
                display: flex;

                &:not(:last-child) {
                    margin-bottom: rem(50px);
                }

                & > .icon {
                    color: color(blue);
                    margin-right: rem(40px);
                }

                div {

                    h5 {
                        color: color(blue);
                        line-height: 1em;
                        margin-bottom: rem(12px);
                    }

                    p {
                        line-height: 1.2em;
                        margin: 0 0 rem(22.5px);

                        &:not(.light) {
                            margin-bottom: 0;
                        }

                        a.social {
                            line-height: 0;
                            margin-right: rem(24px);
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: rem(1320px)) {
            padding-left: rem(15px);
            padding-right: rem(15px);
        }

        @media only screen and (max-width: rem(520px)) {

            ul {

                li {
                    flex-direction: column;

                    & > .icon {
                        margin-bottom: rem(20px);
                    }
                }
            }
        }
    }
}
