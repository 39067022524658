@import 'styles/mixins.scss';

//
// Page : Homepage
// slug : /
//
.homepage {

    .homepage-agency {
        margin-bottom: rem(224px);
    }
    
    .homepage-realisations {
        margin-bottom: rem(128px);
        
        .homepage-realisations-list {
            position: relative;
            margin-bottom: rem(64px);
        }
    }
}