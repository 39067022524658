@import 'mixins.scss';


//
// Commons
//
html,
body {
	color: color(white);
	font-family: 'Poppins';
	margin: 0;
	padding: 0;
	position: relative;

	* {
		box-sizing: border-box;
	}

	// Styled scrollbar
	scrollbar-color: color(blue-dark) color(blue);

	&::-webkit-scrollbar {
		width: rem(16px);
		height: rem(16px);
	}

	&::-webkit-scrollbar-thumb {
		background-color: color(blue-dark);
	}

	&::-webkit-scrollbar-track {
		background: color(blue);
	}

    &.is-orange {
		scrollbar-color: color(orange-dark) color(orange);

		&::-webkit-scrollbar-thumb {
			background-color: color(orange-dark);
		}

		&::-webkit-scrollbar-track {
			background: color(orange);
		}
	}

	&.is-white {
		color: color(black);
		scrollbar-color: color(grey) color(white);

		&::-webkit-scrollbar-thumb {
			background-color: color(grey);
		}

		&::-webkit-scrollbar-track {
			background: color(white);
		}
	}
}

body {
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

#root .App {
	position: relative;
	background-image: url('../../src/images/background.png'), linear-gradient(90deg, #027280 0%, #5BACB2 100%);
	transition: background .1s linear;

	.is-orange & {
		background-image: url('../../src/images/background.png'), linear-gradient(270deg, #E48071 0%, #F05948 100%);
	}
	
	.is-white & {
		background-image: url('../../src/images/background_darker.png'), linear-gradient(270deg, #ffffff 0%, #ffffff 100%);
	}
}


//
// Gabarit
//
main {
	margin: 0 auto;
	max-width: rem(1260px);
	min-height: 100vh;
	position: relative;
	padding-top: rem(40px);
	text-align: left;
	width: 100%;
	z-index: 2;

	@media only screen and (max-width: rem(1280px)) {
		max-width: rem(1280px);
		padding-left: rem(10px);
		padding-right: rem(10px);
	}

	@media only screen and (max-width: rem(861px)) {
		padding-top: rem(80px);
	}
}


//
// Columns
//
.columns {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0;

	.column-full         { width: 100%; }
	.column-threequarter { width: calc(75% - #{rem(10px)}); }
	.column-twothird     { width: calc(66.66% - #{rem(10px)}); }
	.column-half         { width: calc(50% - #{rem(10px)}); }
	.column-third        { width: calc(33.33% - #{rem(10px)}); }
	.column-quarter      { width: calc(25% - #{rem(10px)}); }

	@media only screen and (max-width: rem(768px)) {
		.column-full,
		.column-threequarter,
		.column-twothird,
		.column-half,
		.column-third,
		.column-quarter {
			width: 100%;
		}
	}
}

// Align
.align-left   { text-align: left; }
.align-center { text-align: center; }
.align-right  { text-align: right; }

@media only screen and (max-width: rem(768px)) {
	.mobile-align-left   { text-align: left; }
	.mobile-align-center { text-align: center; }
	.mobile-align-right  { text-align: right; }
}

// Vertical align
.valign-top    { align-items: flex-start; }
.valign-center { align-items: center; }
.valign-bottom { align-items: flex-end; }

// Overflow
.overflow { overflow: hidden; }

// Order (Mobile)
@media only screen and (max-width: rem(768px)) {
	.mobile-first  { order: 1; }
	.mobile-second { order: 2; }
	.mobile-third  { order: 3; }
	.mobile-fourth { order: 4; }
}

// Hide (Mobile)
@media only screen and (max-width: rem(768px)) {
	.mobile-hide { display: none; }
}

// Margin (Mobile)
@media only screen and (max-width: rem(768px)) {
	.mobile-margin-bottom { margin-bottom: rem(40px); }
	.mobile-margin-top    { margin-top: rem(40px); }
}


//
// Typo
//
h1 {
	font-size: rem(45px);
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	line-height: 1.2em;
	margin: 0 0 rem(28px);

	@media only screen and (max-width: rem(768px)) {
		font-size: rem(32px);
	}
}

h1.bigger {
	font-size: rem(90px);
	line-height: 1.2em;
	text-transform: uppercase;

	@media only screen and (max-width: rem(768px)) {
		font-size: rem(35px);
	}
}

h2 {
	font-size: rem(36px);
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	line-height: 1.2em;
	margin: 0 0 rem(16px);

	@media only screen and (max-width: rem(768px)) {
		font-size: rem(28px);
	}
}

h3 {
	font-size: rem(27px);
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	line-height: 1.2em;
	margin: 0 0 rem(16px);

	@media only screen and (max-width: rem(768px)) {
		font-size: rem(23px);
	}
}

h4 {
	font-size: rem(24px);
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	line-height: 1.2em;
	margin: 0 0 rem(16px);

	@media only screen and (max-width: rem(768px)) {
		font-size: rem(21px);
	}
}

h5 {
	font-size: rem(21px);
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	line-height: 1.2em;
	margin: 0 0 rem(16px);

	@media only screen and (max-width: rem(768px)) {
		font-size: rem(19px);
	}
}

h6 {
	font-size: rem(20px);
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	line-height: 1.2em;
	margin: 0 0 rem(16px);

	@media only screen and (max-width: rem(768px)) {
		font-size: rem(18px);
	}
}

p {
	font-size: rem(16px);
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	line-height: 1.4em;
	margin: 0 0 rem(30px);

	&.light {
		font-weight: 300;
	}

	@media only screen and (max-width: rem(768px)) {
		font-size: rem(14px);
	}
}


//
// Links
//
.link {
	color: inherit;
	text-decoration: none;
	text-underline-offset: rem(5px);
	
	&:hover,
	&:focus-visible {
		text-decoration: underline;
		text-underline-offset: rem(5px);
		outline: none;
	}
}

.link__underline {
	color: inherit;
	text-underline-offset: rem(5px);

	&:focus-visible {
		border-radius: rem(3px);
		outline: rem(1px) solid currentColor;
		outline-offset: rem(5px);
		text-decoration: none;
	}
}

.link__icon {
	color: inherit;
	display: inline-block;
	font-size: 0;
    line-height: 0;
	
	&:focus-visible {
		outline: rem(1px) solid currentColor;
		outline-offset: rem(3px);
		border-radius: rem(5px);
	}
}


//
// Forms
//
.form {

}

.form-line {

}


//
// Misc
//
hr {
	background: color(orange);
	border: 0;
	height: rem(3px);
	margin: rem(50px) 0 rem(25px);
	transition: background .1s linear;
	width: 100%;

	.is-orange & {
		background: color(blue);
	}
}

//
// Code (Pre)
//
pre {
	font-size: rem(16px);
	line-height: rem(25px);
}

code {
	background: rgb(39, 40, 34);
	border-radius: rem(5px);
	color: rgb(248, 248, 242);
	line-height: rem(25px);
	padding: 0 rem(8px);
	text-shadow: rgba(0, 0, 0, 0.3) 0px 1px;
}

pre,
code {
	display: inline-block;
	font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
}

pre,
pre code {
	width: 100%;
}

pre code {
	padding: 0;
}



ul.no-prefix,
ol.no-prefix {
	list-style-type: none;
	padding: 0;

	&.list {
		list-style-type: disc;
		margin: 0 rem(15px) rem(30px);

		li {
			color: color(white);
			font-size: rem(16px);
			font-family: 'Poppins';
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}
}

ul:not(.no-prefix),
ol:not(.no-prefix) {
	list-style-type: none;
	margin: 0 0 rem(30px);
	padding: 0;

	& > li {
		font-size: rem(16px);
		margin-left: rem(15px);
		position: relative;

		&::before {
			content: '';
			display: inline-block;
			position: absolute;
			left: rem(-15px);
			top: 0;
		}

		&:not(:last-child) {
			margin-bottom: rem(8px);
		}
	}

	&.no-prefix > li::before {
		display: none;
	}
}

ul:not(.no-prefix) {

	& > li {

		&::before {
			background: color(orange-dark);
			border-radius: 50%;
			height: rem(8px);
			margin-top: rem(8px);
			min-width: rem(8px);
			width: rem(8px);
		}
	}

	.is-orange & li::before { background: color(blue-light); }
}

ol:not(.no-prefix) {
	counter-reset: ol;

	& > li {
		counter-increment: ol;

		&::before {
			content: counter(ol);
			font-size: rem(24px);
			color: color(orange-dark);
			height: rem(30px);
			margin-top: rem(-6px);
			text-align: right;
			width: rem(30px);
			min-width: rem(30px);
			position: absolute;
			left: rem(-35px);
			top: 0;
		}
	}

	.is-orange & li::before { color: color(blue-light); }
}

table {
	border-spacing: 0;
	margin-bottom: rem(40px);

	th,
	td {
		height: rem(33px);
		padding: 0 rem(27px);
	}

	thead {
		color: color(white);
		font-weight: 600;

		tr {
			&:first-child {
				th:first-child { border-top-left-radius: rem(15px); }
				th:last-child { border-top-right-radius: rem(15px); }
			}

			th {
				background: color(blue);

				&:first-child { border-left: rem(1px) solid color(blue); }
				&:last-child { border-right: rem(1px) solid color(blue); }
			}
		}
	}
	
	tbody {

		tr {

			td {
				background: color(white);
				border-bottom: rem(1px) solid rgba(0, 0, 0, .3);
				color: color(black);

				&:first-child { border-left: rem(1px) solid rgba(0, 0, 0, .3); }
				&:last-child { border-right: rem(1px) solid rgba(0, 0, 0, .3); }
			}

			&:last-child {

				td:first-child { border-bottom-left-radius: rem(15px); }
				td:last-child { border-bottom-right-radius: rem(15px); }
			}
		}
	}
}

abbr {
	text-decoration: underline dotted;
}

address {
	color: color(white);
	text-align: justify;
	font-size: rem(16px);
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 300;
	line-height: normal;
	margin: 0 0 rem(30px);
}

blockquote {
	border-left: rem(5px) solid color(orange);
	margin: 0 0 rem(30px);
	padding: rem(10px) rem(20px);
	width: 100%;
	z-index: 1;
	
	p {
		display: inline;
		margin: 0;
		position: relative;
	}
}