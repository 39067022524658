@import 'styles/mixins.scss';

.footer {
    background: color(orange-dark);
    border-top: rem(16px) solid color(white);
    color: color(white);
    position: relative;
    transition: background .1s linear;
    z-index: 1;

    p {
        margin-bottom: rem(16px);

        &.mail {
            color: color(white);
            font-size: rem(20px);
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            line-height: rem(54px);
        }
    }

    .footer__wrapper {
        margin: 0 auto;
        max-width: rem(1280px);
        padding: rem(24px) rem(32px);
        width: 100%;

        .footer__links {

            ul {
                margin: 0;
            }
        }

        .footer__contact {

            h3 {
                margin-bottom: rem(12px);
                padding-bottom: rem(8px);

                &:nth-of-type(1) {
                    display: inline-block;
                    border-bottom: rem(9px) solid color(blue);
                    padding-right: rem(80px);
                }
            }

            a.social {
                margin-right: rem(32px);
            }
        }

        .footer__mentions {
            margin-top: rem(58px);
            
            p {
                font-weight: 300;
                margin-bottom: 0;
            }
        }
    }

    .is-orange & {
		background: color(blue);

        .footer__wrapper .footer__contact h3 {
            border-bottom-color: color(orange);
        }
	}
}
