@import 'styles/mixins.scss';


.card {
    background: color(white);
    border-radius: rem(15px);
    box-shadow: rem(1px) rem(12px) rem(14px) 0 rgba(0, 0, 0, .25);
    color: color(grey-dark);
    display: inline-block;
    isolation: isolate;
    overflow: hidden;
    outline: none;
    position: relative;
    width: rem(400px);
    max-width: 100%;

    .card__visuel {
        background: color(grey);
        display: inline-block;
        position: relative;
        width: 100%;
        
        .card__thumbnail {
            align-items: center;
            display: flex;
            height: auto;
            justify-content: center;
            overflow: hidden;
            width: 100%;
            z-index: 1;
        
            img {
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
    
            .logo {
                filter: grayscale(1);
                height: rem(300px);
                opacity: .1;
                padding: rem(60px) 0;
            }
        }

        .card__taglist {
            bottom: rem(15px);
            display: flex;
            flex-wrap: wrap;
            left: rem(15px);
            margin: 0;
            position: absolute;
            right: rem(15px);
            z-index: 1;

            li {
                margin-top: rem(10px);

                &:not(:last-child) {
                    margin-right: rem(10px);
                }
            }
        }
    }

    .card__description {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        font-size: rem(16px);
        justify-content: center;
        padding: rem(15px) rem(20px);
        
        .title {
            font-weight: 600;
        }

        p {
            display: block;
            margin: 0;
            text-align: left;
            width: 100%;
            
            &.title {
                font-weight: 600;
                text-overflow: ellipsis;
                line-height: rem(40px);
                height: rem(40px);
                overflow: hidden;
                min-height: rem(40px);
                white-space: nowrap;
            }

            &.light {
                line-height: rem(20px);
                //height: rem(70px);
                min-height: rem(70px);
            }
        }

        .date {
            display: block;
            font-size: rem(14px);
            margin-bottom: rem(15px);
            margin-top: rem(-5px);
        }
        
        .link__underline {
            align-self: flex-end;
            font-size: rem(14px);
            line-height: rem(40px);
            min-height: rem(40px);
        }
    }
}
