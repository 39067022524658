@import 'styles/mixins.scss';

//
// Page : Services
// slug : /nos-services
//
.services {
    padding-bottom: rem(64px);

    .service {
        background: color(white);
        border-radius: rem(15px);
        padding: rem(20px) clamp(#{rem(20px)}, 10vw, #{rem(160px)});
        margin-bottom: rem(20px);

        .column-auto {
            line-height: 0;
            flex: 1;

            &.text { flex: 2; }
            
            @media only screen and (min-width: rem(769px)) {
                &:first-child  { padding-right: rem(20px); }
                &:last-child { padding-left: rem(20px); }
            }
        }

        h3 {
            color: color(blue);
            margin-bottom: rem(10px);
        }

        p {
            color: color(grey-dark);
            margin-bottom: 0;
        }

        picture {
            display: inline-block;
            height: auto;
            max-width: 100%;

            img {
                max-width: 100%;
                width: rem(300px);
                height: rem(200px);
            }
        }

        @media only screen and (max-width: rem(768px)) {
            padding: rem(20px);

            .column-auto {
                width: 100%;
                min-width: 100%;

                &.mobile-first {
                    margin-bottom: rem(40px);
                }
            }
        }
    }
}