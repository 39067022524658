@import 'styles/mixins.scss';

.picto {
    align-items: center;
    display: inline-flex;
    justify-content: center;

    svg {
        height: auto;
        width: 100%;

        .color1 { color: color(orange); }
        .color2 { color: color(orange-light); }
        .color3 { color: color(orange-dark); }

        .color4 { color: color(blue); }
        .color5 { color: color(blue-light); }
        .color6 { color: color(blue-dark); }
    }
}


//
// Size
//
.picto {

    &.smaller {
        width: rem(75px);
        height: rem(75px);
    }

    &.small {
        width: rem(150px);
        height: rem(150px);
    }

    &.big {
        width: rem(250px);
        height: rem(250px);
    }

    &.huge {
        width: rem(375px);
        height: rem(375px);
    }

    @media only screen and (max-width: rem(1000px)) {
        &.big {
            width: rem(150px);
            height: rem(150px);
        }

        &.huge {
            width: rem(250px);
            height: rem(250px);
        }
    }
}