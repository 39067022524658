@import 'styles/mixins.scss';


.tag {
    align-items: center;
    border-radius: rem(12px);
    box-shadow: 0 rem(4px) rem(4px) 0 rgba(0, 0, 0, .25);
    color: color(white);
    font-style: italic;
    font-size: rem(14px);
    font-weight: 300;
    height: rem(24px);
    display: inline-flex;
    padding: 0 rem(10px);
    white-space: nowrap;

    &.tag-dev { background: color(blue); }
    &.tag-crea { background: color(orange); }
    &.tag-misc { background: color(grey); }
}