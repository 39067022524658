@import 'styles/mixins.scss';

.hero {
    height: 100vh;
    margin-top: rem(-140px);
    position: relative;
    z-index: -1;

    * {
        will-change: opacity, x, y;
    }

    .hero__container {
        align-items: center;
        display: flex;
        height: 100svh;
        justify-content: center;
        position: fixed;
        top: 0;
        inset: 0;
        margin-left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        max-width: 100%;
    
        .title {
            margin-bottom: 0;
            position: absolute;
            text-shadow: 0 rem(4px) rem(4px) rgba(0, 0, 0, .25);
            transition: opacity .1s linear;
            white-space: nowrap;
            z-index: 2;

            &.position-1 {
                font-size: clamp(#{rem(35px)}, #{px2vw(90px, 1920px)}, #{rem(90px)});
            }
        }

        .hero__logo_homepage,
        .hero__logo {
            font-size: 0;
            position: fixed;
            transition: opacity .1s linear;
            z-index: 2;
        }

        .hero__logo_homepage {
            height: px2vw(289px, 1920px);
            right: px2vw(210px, 1920px);
            top: rem(135px);
            width: auto;

            .logo {
                height: 100%;
            }
        }

        .hero__logo {
            left: 50%;
            max-width: rem(1260px);
            padding: 0 rem(10px) 0 rem(35px);
            top: rem(37px);
            transform: translateX(-50%);
            width: calc(100vw - #{rem(30px)});
        }    
    }

    &.hidden {
        pointer-events: none;
    }

    // SVG Scene
    .hero__scene {
        height: 100vh;
        width: auto;
        position: absolute;
        inset: 0;
        transform: translateX(#{rem(-1px)});
        z-index: 0;

        &.hero__scene_large {
            height: auto;
            width: 100vw;
        }

        .shape {
            transition: fill .1s linear;

            &.shape0 { filter: drop-shadow(#{rem(27px)} #{rem(-5px)} #{rem(51px)} rgba(0, 0, 0, .25)); }
            &.shape1 { filter: drop-shadow(#{rem(26px)} #{rem(-14px)} #{rem(37px)} rgba(0, 0, 0, .25)); }
            &.shape2 { filter: drop-shadow(#{rem(28px)} #{rem(4px)} #{rem(37px)} rgba(8, 50, 63, .6)); }
        }

        .wave {
            transition: fill .1s linear;

            &.wave0 { filter: drop-shadow(#{rem(27px)} #{rem(-5px)} #{rem(51px)} rgba(0, 0, 0, .25)); }
            &.wave1 { filter: drop-shadow(#{rem(27px)} #{rem(-5px)} #{rem(51px)} rgba(0, 0, 0, .25)); }
            &.wave1bis { filter: drop-shadow(#{rem(6px)} #{rem(-10px)} #{rem(37px)} rgba(0, 0, 0, .25)); }
            &.wave2 { filter: drop-shadow(#{rem(-1px)} #{rem(-9px)} #{rem(37px)} rgba(163, 45, 36, .5)); }
        }

        .letter {
            transition: fill .1s linear, stroke .1s linear;

            &.letterB,
            &.letterL,
            &.letterO,
            &.letterG { filter: drop-shadow(#{rem(-1px)} #{rem(-9px)} #{rem(37px)} rgba(255, 255, 255, .5)); }
        }

        .dash {
            transition: fill .1s linear, stroke .1s linear;
        }
    }

    // Title position
    .hero__container {

        &.hero__position_1 .title { left: px2vw(118px, 1920px); bottom: px2vw(156px, 1920px); }
        &.hero__position_2 .title,
        &.hero__position_3 .title,
        &.hero__position_4 .title { left: px2vh(900px, 937px); top: 50vh; transform: translateY(-50%); }
        &.hero__position_5 .title { left: px2vh(750px, 937px); top: 50vh; transform: translateY(-50%); }

        &.hero__position_6 .title {
            display: none;
        }
    }

    @media only screen and (max-width: rem(1600px)) {
        .hero__scene:not(.hero__scene_large) {
            transform: translateX(#{px2vh(-150px, 937px)});
        }
        
        .hero__container {
            &.hero__position_2 .title,
            &.hero__position_3 .title,
            &.hero__position_4 .title { left: px2vh(750px, 937px); }
            &.hero__position_5 .title { left: px2vh(600px, 937px); }
        }
    }

    @media only screen and (max-width: rem(1310px)) {
        .hero__scene:not(.hero__scene_large) {
            transform: translateX(#{px2vh(-250px, 937px)});
        }
        
        .hero__container {
            &.hero__position_2 .title,
            &.hero__position_3 .title,
            &.hero__position_4 .title { left: px2vh(650px, 937px); }
            &.hero__position_5 .title { left: px2vh(500px, 937px); }
        }
    }

    @media only screen and (max-width: rem(1100px)) {
        
        .hero__container {
            &.hero__position_2 .title,
            &.hero__position_3 .title,
            &.hero__position_4 .title,
            &.hero__position_5 .title { left: rem(50px); }
        }
    }

    @media only screen and (max-width: rem(840px)) {
        .hero__scene.hero__scene_large {
            width: rem(840px);
            inset: 0 0 0 auto;
        }

        .hero__container {            
            &.hero__position_1 .title {
                bottom: auto;
                left: rem(20px);
                top: rem(415px);
            }

            .hero__logo_homepage {
                height: rem(126px);
                left: auto;
                right: rem(-15px);
                transform: inherit;
                width: auto;
            }
        }
    }
}

// Background blue
body .hero {

    .shape {
        &.shape0 { fill: color(orange-dark); }
        &.shape1 { fill: color(orange); }
        &.shape2 { fill: color(orange-light); }
    }

    .wave {
        &.wave0 { fill: color(orange-dark); }
        &.wave1 { fill: color(orange); }
        &.wave1bis { fill: color(orange-bis); }
        &.wave2 { fill: color(orange-light); }
    }

    .letter {
        fill: color(white);
        stroke: color(white);
    }

    .dash {
        fill: color(orange);
        stroke: color(orange);
    }
}

// Background orange
body.is-orange .hero {

    .shape {
        &.shape0 { fill: color(blue-dark); }
        &.shape1 { fill: color(blue); }
        &.shape2 { fill: color(blue-light); }
    }

    .wave {
        &.wave0 { fill: color(blue-dark); }
        &.wave1 { fill: color(blue); }
        &.wave1bis { fill: color(blue-bis); }
        &.wave2 { fill: color(blue-light); }
    }

    .letter {
        fill: color(white);
        stroke: color(white);
    }

    .dash {
        fill: color(blue);
        stroke: color(blue);
    }
}

// Background white
body.is-white .hero {

    .hero__container {
    
        .title {
            color: color(blue);
        }
    }

    .shape {
        &.shape0 { fill: color(blue); }
        &.shape1 { fill: color(blue-bis); }
        &.shape2 { fill: color(blue-light); }
    }

    .wave {
        &.wave0 { fill: color(blue-dark); }
        &.wave1 { fill: color(blue); }
        &.wave1bis { fill: color(blue-bis); }
        &.wave2 { fill: color(blue-light); }
    }

    .letter {
        fill: color(blue);
        stroke: color(blue);
    }

    .dash {
        fill: color(orange);
        stroke: color(orange);
    }
}