@import 'styles/mixins.scss';

//
// Page : Error
// slug : *
//
.error {
    min-height: calc(100vh - #{rem(125px)});

    .button {
        bottom: rem(20px);
        left: 50%;
        transform: translateX(-50%);
        position: fixed;
        z-index: 20;
    }

    & + .footer {
        display: none;
    }
}