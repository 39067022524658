@import 'styles/mixins.scss';

//
// Page : Realisation
// slug : /realisations/**
//

.realisation {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: rem(-100px);
    max-width: inherit;
    padding-top: 0;
    position: relative;

    .realisation_container {
        display: flex;
        flex-wrap: wrap;
        max-width: rem(1260px);
        width: 100%;
    }

    @media only screen and (max-width: rem(1280px)) {
        padding-left: 0;
        padding-right: 0;
    }

    #project__hero {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        z-index: 2;

        img {
            align-items: center;
            display: flex;
            height: rem(500px);
            justify-content: center;
            object-fit: cover;
            order: 1;
            width: 100%;

            @media only screen and (max-width: rem(767px)) {
                height: rem(300px);
            }
        }
    }

    #project__taglist {
        display: flex;
        flex-wrap: wrap;
        left: 50%;
        margin: 0 auto;
        max-width: min(#{rem(1260px)}, 100vw);
        position: absolute;
        top: rem(500px);
        transform: translateX(-50%) translateY(-100%);
        width: 100%;
        z-index: 3;
        
        li {
            margin-bottom: rem(10px);
            
            &:not(:last-child) {
                margin-right: rem(10px);
            }
        }

        @media only screen and (max-width: rem(1280px)) {
            max-width: 100%;
            padding-left: rem(10px);
            padding-right: rem(10px);
        }

        @media only screen and (max-width: rem(767px)) {
            top: rem(300px);
        }
    }
    
    #project__title {
        display: flex;
        flex-direction: column;
        margin-bottom: rem(40px);
        position: relative;
        width: 100%;
        z-index: 2;

        p {
            color: color(grey-dark);
            font-size: rem(12px);
            margin: rem(10px) auto 0;
            max-width: rem(1260px);
            order: 2;
            text-align: right;
            width: 100%;

            @media only screen and (max-width: rem(1280px)) {
                max-width: 100%;
                padding-left: rem(10px);
                padding-right: rem(10px);
            }
        }
        
        h1 {
            color: color(blue-dark);
            margin: 0 auto;
            max-width: rem(1260px);
            order: 3;
            padding: rem(42px) rem(21px);
            text-align: center;
            width: 100%;
        }
    }

    #project__objectives {
        color: color(grey-dark);
        max-width: 66.66%;
        padding-right: rem(80px);
        position: relative;
        width: rem(840px);
        z-index: 2;
        
        h3 {
            color: color(blue-dark);
        }

        p {
            line-height: rem(23px);
        }
        
        @media only screen and (max-width: rem(1280px)) {
            padding-left: rem(10px);
            width: rem(850px);
        }

        @media only screen and (max-width: rem(767px)) {
            margin-bottom: rem(40px);
            max-width: 100%;
            padding-right: rem(10px);
            width: 100%;
        }
    }
    
    #project__solutions {
        color: color(grey-dark);
        max-width: calc(33.33%);
        position: relative;
        width: rem(420px);
        z-index: 2;
        
        h3 {
            color: color(blue-dark);
        }
        
        @media only screen and (max-width: rem(1280px)) {
            padding-right: rem(10px);
            width: rem(430px);
        }

        @media only screen and (max-width: rem(767px)) {
            max-width: 100%;
            padding-left: rem(10px);
            width: 100%;
        }
    }

    #project__contact {
        align-items: center;
        color: color(grey-dark);
        display: flex;
        flex-direction: column;
        margin: rem(60px) auto;
        max-width: rem(1260px);
        position: relative;
        width: 100%;
        z-index: 2;
    }

    #project__gallery,
    #project__mockup {
        margin: 0 auto rem(40px);
        max-width: rem(1260px);
        width: 100%;
    }
    
    #project__more {
        display: flex;
        justify-content: space-between;
        margin: 0 auto rem(40px);
        max-width: rem(1260px);
        position: relative;
        width: 100%;
        z-index: 2;

        .project__more_item {
            width: calc(33.33% - #{rem(10px)});
            text-align: center;
        }

        .project__more_presentation {
            align-items: center;
            background: color(blue);
            border-radius: rem(15px);
            box-shadow: 0 0 rem(15px) 0 rgba(0, 0, 0, .25);
            display: inline-flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            margin: 0 auto;
            max-width: rem(400px);

            h3 {
                color: color(white);
                margin-bottom: rem(40px);
                padding: 0 rem(20px);
                text-align: center;
            }

            .button {
                text-align: center;
                z-index: 1;
                width: auto;
            }
        }

        @media only screen and (max-width: rem(1280px)) {
            max-width: 100%;
            padding-left: rem(10px);
            padding-right: rem(10px);
        }

        @media only screen and (max-width: rem(1080px)) {
            flex-wrap: wrap;

            .project__more_item:nth-child(1) {
                margin-bottom: rem(20px);
                width: 100%;

                .project__more_presentation {
                    height: auto;
                    max-width: inherit;
                    padding: rem(25px);
                    width: 100%;
                }
            }

            .project__more_item:nth-child(2),
            .project__more_item:nth-child(3) {
                width: calc(50% - #{rem(20px)});
            }
        }

        @media only screen and (max-width: rem(767px)) {
            .project__more_item:nth-child(1),
            .project__more_item:nth-child(2),
            .project__more_item:nth-child(3) {
                width: 100%;
            }

            .project__more_item {
                margin-bottom: rem(20px);
            }
        }
    }
}