@import 'styles/mixins.scss';

.icon {
    align-items: center;
    display: inline-flex;
    justify-content: center;

    svg {
        fill: currentColor;
        height: 100%;
        width: 100%;
    }
}


//
// Size
//
.icon {

    &.smaller {
        width: rem(23px);
        height: rem(23px);
    }

    &.small {
        width: rem(40px);
        height: rem(40px);
    }

    &.big {
        width: rem(50px);
        height: rem(50px);
    }

    &.huge {
        width: rem(75px);
        height: rem(75px);
    }
}