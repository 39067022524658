@import 'styles/mixins.scss';

.logo {
    display: inline-flex;
    width: auto;

    svg {
        height: 100%;
        width: auto;
    }

    &.smaller { height: rem(66px); }
    &.small   { height: rem(141px); }
    &.big     { height: rem(189px); }
    &.huge    { height: rem(289px); }
}

// Background bleu   : Blanc / Orange
// Background orange : Blanc / Bleu
// Background blanc  : Bleu / Orange
.logo.v0 {

    .color1 { fill: color(white); }
    .color2 { fill: color(blue); }
    .color3 { fill: color(white); }

    .is-orange & {
        .color1 { fill: color(white); }
        .color2 { fill: color(orange); }
        .color3 { fill: color(white); }
    }

    .is-white & {
        .color1 { fill: color(blue); }
        .color2 { fill: color(orange); }
        .color3 { fill: color(grey); }
    }
}

// Bleu / Orange
.logo.v1 {
    .color1 { fill: color(blue); }
    .color2 { fill: color(orange); }
    .color3 { fill: color(grey); }
}

// Bleu / Blanc
.logo.v2 {
    .color1 { fill: color(blue); }
    .color2 { fill: color(white); }
    .color3 { fill: color(grey); }
}

// Blanc / Orange
.logo.v3 {
    .color1 { fill: color(white); }
    .color2 { fill: color(orange); }
    .color3 { fill: color(white); }
}

// Blanc / Bleu
.logo.v4 {
    .color1 { fill: color(white); }
    .color2 { fill: color(blue); }
    .color3 { fill: color(white); }
}

// Orange / Blanc
.logo.v5 {
    .color1 { fill: color(orange); }
    .color2 { fill: color(white); }
    .color3 { fill: color(grey); }
}

// Orange / Bleu
.logo.v6 {
    .color1 { fill: color(orange); }
    .color2 { fill: color(blue); }
    .color3 { fill: color(grey); }
}