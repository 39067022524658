@import 'styles/mixins.scss';

//
// Page : Mentions
// slug : /mentions
//
.mentions {

    a {
        color: color(white);
    }
}