@import 'styles/mixins.scss';

//
// Page : Realisations
// slug : /realisations-sur-mesure
//
.realisations {

    .realisations-presentation {
        margin-bottom: rem(64px);
    }
    
    .realisations-list {
        margin-bottom: rem(64px);

        .column-third {
            margin-bottom: rem(32px);
        }

        .column-full {
            margin-top: rem(64px);
        }
    }
}