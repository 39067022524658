@import 'styles/mixins.scss';

.button {
    align-items: center;
    appearance: none;
    border: none;
    background: transparent;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    font-family: Poppins;
    font-weight: 600;
    justify-content: center;
    margin: 0;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    transition: all .15s ease-in-out;

    &::before,
    &::after {
        content: '';
        position: absolute;
        inset: 0;
        transition: all .15s ease-in-out;
    }

    &::before {
        z-index: -2;
    }

    &::after {
        z-index: -1;
    }

    &:focus {
        outline: none;
    }
}

//
// Animations
//
.button {

    &.v1,
    &.v2,
    &.v3,
    &.v4 {
        &::after {
            height: calc(100% + #{rem(20px)});
            width: calc(100% + #{rem(40px)});
            border-radius: rem(35px);
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-150%);
        }


        &:hover::after,
        &:focus-visible::after {
            transform: translateY(-50%) translateX(-50%);
        }
    }

    &.v5,
    &.v6 {
        &::after {
            height: calc(100% + #{rem(20px)});
            bottom: 0;
            transform: translateY(-100%);
        }

        &:hover::after,
        &:focus-visible::after {
            transform: translateY(0);
        }
    }
}

//
// Versions
//
.button {
    // Common
    &:hover,
    &:focus-visible {
        box-shadow: 0 rem(4px) rem(4px) rgba(0, 0, 0, .25);
    }

    &:active {
        transition: all 0s;
    }

    // Orange / Blanc
    &.v1 {
        color: color(white);

        &::before { background: color(orange); }
        &::after { background: color(orange-dark); }

        &:active {
            background: color(white);
            color: color(orange);
        }
    }

    // Bleu / Blanc
    &.v2 {
        color: color(white);

        &::before { background: color(blue); }
        &::after { background: color(blue-dark); }

        &:active {
            background: color(white);
            color: color(blue);
        }
    }

    // Blanc / Bleu
    &.v3 {
        color: color(blue);

        &::before { background: color(white); }
        &::after { background: color(blue); }

        &:hover {
            color: color(white);
        }

        &:active {
            background: color(blue-light);
            color: color(blue-dark);
        }
    }

    // Blanc / Orange
    &.v4 {
        color: color(orange);

        &::before { background: color(white); }
        &::after { background: color(orange); }

        &:hover {
            color: color(white);
        }

        &:active {
            background: color(orange-light);
            color: color(orange-dark);
        }
    }

    // Orange Gradient
    &.v5 {
        color: color(white);

        &::before { background: linear-gradient(0deg, #{color(orange)} 0%, #{color(orange-light)} 100%); }
        &::after { background: linear-gradient(180deg, #{color(orange)} 0%, #{color(orange-light)} 100%); }

        &:active {
            background: color(orange);
        }
    }

    &.v6 {
        color: color(white);

        &::before,
        &::after {
            border: none !important;
            content: '';
            inset: 0;
            position: absolute;
            transition: transform .15s linear;
        }

        &::before { background: linear-gradient(180deg, #{color(blue-dark)} 0%, #{color(blue-light)} 100%); }
        &::after { background: linear-gradient(0deg, #{color(blue-dark)} 0%, #{color(blue-light)} 100%); }

        &:active {
            background: color(blue);
        }
    }
}

//
// Size
//
.button {

    &.smaller {
        border-radius: rem(5px);
        font-size: rem(14px);
        height: rem(44px);
        padding: 0 rem(15px);
    }

    &.small {
        border-radius: rem(10px);
        font-size: rem(22px);
        height: rem(66px);
        padding: 0 rem(35px);
    }

    &.big {}

    &.bigger {}

    @media only screen and (max-width: rem(768px)) {

        &.smaller {
            padding: 0 rem(10px);
        }

        &.small {
            font-size: rem(18px);
            height: rem(55px);
            padding: 0 rem(25px);
        }
    }
}